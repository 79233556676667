// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFBJFG8ldZZUXSoZcf_wEJC1LoTmc9LvI",
  authDomain: "e-light-productions-cms.firebaseapp.com",
  projectId: "e-light-productions-cms",
  storageBucket: "e-light-productions-cms.appspot.com",
  messagingSenderId: "46031329223",
  appId: "1:46031329223:web:1cf7df5f66c3b0104d6be2",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Firebase storage reference
const storage = getStorage(app);
const auth = getAuth(app);

export { storage, auth };
