import styled from "styled-components";

export const Wrapper = styled("div")`
  display: flex;
  justify-content: center;
`;

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  width: 30%;
`;
