import styled from "styled-components";
import { symbols } from "../../themes/Symbols";
import { HeadingMedium, Text } from "../Typography";

export const CardGroup = styled.div`
  margin: 0 -${symbols.spacing._16};
  display: flex;
  flex-direction: column;

  @media (min-width: ${symbols.media.large}) {
    flex-direction: row;
  }
  @media (min-width: ${symbols.media.xLarge}) {
    flex-direction: row;
  }
`;

export const CardWrapper = styled.div`
  overflow: hidden;
  flex: 1;
  border-radius: 4px;
  margin: 0 ${symbols.spacing._16};
  background-color: ${symbols.colors.white};
  margin-bottom: ${symbols.spacing._30};

  @media (min-width: ${symbols.media.large}) {
    margin-bottom: 0;
  }
  @media (min-width: ${symbols.media.xLarge}) {
    margin-bottom: 0;
  }
`;

export const CardImage = styled.img`
  width: 100%;
  height: auto;
`;

export const CardTitle = styled(HeadingMedium)`
  color: ${symbols.colors.black};
  padding-bottom: ${symbols.spacing._20};
`;

export const CardText = styled(Text)`
  color: ${symbols.colors.black};
  padding-bottom: ${symbols.spacing._20};
`;

export const CardBody = styled.div`
  padding: ${symbols.spacing._20};
`;
