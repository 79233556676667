import React, { useState } from "react";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import LogoImg from "../../assets/images/logo.png";
import { BurgerIcon, Logo, NavBar, NavLink } from "./styles";
import { useLocation } from "react-router-dom";

const Navbar = () => {
  const [expanded, setExpanded] = useState(false);
  const { pathname } = useLocation();

  const handleToggle = (e: any) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  return (
    <>
      <Logo alt="Elight Productions | Logo" src={LogoImg} />
      <>
        <BurgerIcon icon={faBars} onClick={handleToggle} />
        <NavBar className={`collapsed ${expanded ? "expanded" : ""}`}>
          <NavLink to="/" active={pathname === "/"}>
            Home
          </NavLink>
          <NavLink to="/testimonials" active={pathname === "/testimonials"}>
            Testimonials
          </NavLink>
          <NavLink to="/gallery" active={pathname === "/gallery"}>
            Gallery
          </NavLink>
          <NavLink to="/contact" active={pathname === "/contact"}>
            Contact
          </NavLink>
        </NavBar>
      </>
    </>
  );
};

export default Navbar;
