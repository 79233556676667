import React, { useEffect, useState } from "react";
import { v4 } from "uuid";
import { ref, uploadBytes } from "firebase/storage";
import { storage } from "../../../../firebase-config";
import { Logout } from "../Logout";
import { Button } from "../../../../components/Button";
import { SuccessText, UploadButtonContainer, Wrapper } from "./styles";
import Spacer from "../../../../components/Spacer";

export const Upload = () => {
  const [file, setFile] = useState<File | undefined>();
  const [preview, setPreview] = useState<string>();
  const [showSuccess, setShowSuccess] = useState<boolean>(false);

  const handleUpload = () => {
    if (!file) {
      alert("Please upload an image first!");
    } else {
      const imageRef = ref(storage, `/images/${file?.name}_${v4()}`);

      uploadBytes(imageRef, file).then(() => {
        setPreview(undefined);
        setFile(undefined);
        setShowSuccess(true);

        setTimeout(() => {
          setShowSuccess(false);
        }, 3000);
      });
    }
  };

  useEffect(() => {
    if (!file) {
      setPreview(undefined);
      return;
    }

    const objectUrl = URL.createObjectURL(file);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [file]);

  return (
    <>
      <Spacer size={20} />
      <Logout>
        <Wrapper>
          <div>
            <UploadButtonContainer>
              <input
                type="file"
                onChange={(event) =>
                  setFile((event.target as HTMLInputElement).files?.[0])
                }
              />
            </UploadButtonContainer>
          </div>
          <Button
            secondary
            onClick={handleUpload}
            buttonText="Upload to Firebase"
            disabled={!preview}
          />
        </Wrapper>

        {showSuccess && (
          <div>
            <Spacer size={30} />
            <SuccessText>Image uploaded successfully!</SuccessText>
          </div>
        )}

        <Spacer size={30} />
        {file && (
          <img
            alt="Preview"
            style={{ width: "560px", border: "1px solid white" }}
            src={preview}
          />
        )}
      </Logout>
      <Spacer size={200} />
    </>
  );
};
