import React, { InputHTMLAttributes } from "react";
import { Input as StyledInput, Wrapper } from "./styles";
import { CSSProp } from "styled-components";

interface Props extends InputHTMLAttributes<HTMLInputElement> {
  inputWrapperStyles?: CSSProp;
}

export const Input = ({
  onChange,
  inputWrapperStyles,
  type = "text",
  ...props
}: Props) => {
  return (
    <Wrapper styles={inputWrapperStyles}>
      <StyledInput onChange={onChange} type={type} {...props} />
    </Wrapper>
  );
};
