import styled, { CSSProp } from "styled-components";
import { symbols } from "../../themes/Symbols";

export const Wrapper = styled("div")<{ styles?: CSSProp }>`
  ${({ styles }) => styles}
`;

export const Input = styled("input")`
  width: 100%;

  font-size: ${symbols.fontSize._14};
  padding: ${symbols.spacing._10};
  border-radius: ${symbols.borderRadius._4};
  border-style: solid;
  border-width: 1px;
  border-color: ${symbols.colors.white};
  &.text-input.error {
    border-color: ${symbols.colors.monza};
  }
`;
