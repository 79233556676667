import React, { PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";
import { useUserAuth } from "../../context/User";

const ProtectedRoute = ({ children }: PropsWithChildren) => {
  const userAuth = useUserAuth();

  if (userAuth?.user !== undefined && !userAuth?.user) {
    return <Navigate to="/login" />;
  }
  return <>{children}</>;
};

export default ProtectedRoute;
