import styled from "styled-components";
import { symbols } from "../../themes/Symbols";
import { HeadingMedium } from "../Typography";

export const FooterWrapper = styled.div`
  background-color: ${symbols.colors.veryDarkGray};
`;

export const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${symbols.spacing._20} 0;

  @media (min-width: ${symbols.media.small}) {
    justify-content: space-between;
    flex-direction: row;
  }
  @media (min-width: ${symbols.media.large}) {
    justify-content: flex-end;
  }
`;

export const FooterGroup = styled.div`
  padding-bottom: ${symbols.spacing._20};
  &:last-of-type {
    padding-bottom: 0;
  }

  @media (min-width: ${symbols.media.large}) {
    padding-left: ${symbols.spacing._100};
    padding-bottom: 0;
  }
  @media (min-width: ${symbols.media.xLarge}) {
    padding-left: ${symbols.spacing._100};
  }
`;

export const FooterHeading = styled(HeadingMedium)`
  color: ${symbols.colors.deepPink};
  margin-bottom: ${symbols.spacing._20};
`;

export const FooterItem = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${symbols.spacing._16};
`;

export const FooterLink = styled.a`
  color: ${symbols.colors.white};
  font-size: ${symbols.fontSize._14};
  line-height: ${symbols.lineHeight._18};
  margin-left: ${symbols.spacing._20};
  text-decoration: none;
`;

export const FooterSocial = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: -${symbols.spacing._20};
`;

export const FooterCopyRightContainer = styled.div`
  padding: ${symbols.spacing._30} 0;
`;
