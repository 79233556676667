import React, { useEffect, useState } from "react";
import Image1 from "../../assets/images/slider-img-1.jpg";
import Image2 from "../../assets/images/slider-img-2.jpg";
import Image3 from "../../assets/images/slider-img-3.jpg";
import Image4 from "../../assets/images/slider-img-4.jpg";
import Image5 from "../../assets/images/slider-img-5.jpg";
import Spacer from "../../components/Spacer";
import Card from "../../components/Card";
import { SlickSlider } from "../../components/Slider";
import {
  HeadingExtraBold,
  Text,
  HeadingBold,
} from "../../components/Typography";
import styled from "styled-components";
import { symbols } from "../../themes/Symbols";
import { CardGroup } from "../../components/Card/styles";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";

const HomeHeading = styled(HeadingExtraBold)`
  margin-bottom: ${symbols.spacing._20};
`;

const HomeServicesHeading = styled(HeadingBold)`
  margin-bottom: ${symbols.spacing._20};
`;

const Home = () => {
  const [imageList, setImages] = useState<string[]>([]);
  const storage = getStorage();
  const listRef = ref(storage, "images/home/");
  // const images = [Image1, Image2, Image3, Image4, Image5];

  useEffect(() => {
    listAll(listRef)
      .then((response) => {
        response.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            setImages((prev) => [...prev, url]);
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.log({ imageList });

  return (
    <>
      <SlickSlider
        images={imageList}
        settings={{
          dots: false,
          infinite: true,
          slidesToShow: 1,
          slidesToScroll: 1,
          autoplay: true,
        }}
      />
      <Spacer size={100} />
      <HomeHeading>Professional mobile bars for hire</HomeHeading>
      <Text large>
        Providing Mobile Bars for all occasions and events around the UK,
        E-light Productions offer the exclusive hire of bespoke LED mobile bars
        in any coloured lighting to match the theme of any event. The unique
        design of these bars blend in with the decor for all occasions, making
        your event a night to remember.
      </Text>
      <Spacer size={50} />
      <HomeServicesHeading>Our Services</HomeServicesHeading>
      <CardGroup>
        <Card
          imgSrc={imageList[0]}
          title="Mobile Bars"
          text="We offer a choice of as many panels an event place can hold, and provide all you need to get the party flowing smoothly! we cater for both corporate and private events, providing bar hire packages for a range of events including, wedding bar hire for receptions, parties, birthdays, anniversaries, business conferences, christmas events, music events, sporting events etc"
        />
        <Card
          imgSrc={imageList[2]}
          title="Events"
          text="If you are looking to create a themed event, we are able to provide the full range of decor to unique mobile bars as per the clients choice, and we will create a venue that will bring your event to life."
        />
        <Card
          imgSrc={imageList[3]}
          title="Decor"
          text="Specialising in transforming events from weddings to corporate events, we know how important it is for our business clients to achieve exactly the right effect. we provide LED back drops, to table settings, chair covers and centre pieces as you require."
        />
      </CardGroup>
      <Spacer size={100}></Spacer>
    </>
  );
};

export default Home;
