import React from "react";
import styled from "styled-components";
import { symbols } from "../../themes/Symbols";

interface Props {
  children: React.ReactNode;
  bgColor?: string;
}
const LayoutWrapper = styled.div<{ bgColor?: string }>`
  background-color: ${(props) =>
    props.bgColor ? props.bgColor : symbols.colors.black};
`;
const LayoutContainer = styled.div`
  padding-right: ${symbols.spacing._16};
  padding-left: ${symbols.spacing._16};
  margin: 0 auto;

  @media (min-width: ${symbols.media.small}) {
    width: ${symbols.size.containerSmall};
  }
  @media (min-width: ${symbols.media.large}) {
    width: ${symbols.size.containerLarge};
  }
  @media (min-width: ${symbols.media.xLarge}) {
    width: ${symbols.size.containerXLarge};
  }
`;

const Layout = ({ bgColor, children }: Props) => {
  return (
    <LayoutWrapper bgColor={bgColor}>
      <LayoutContainer>{children}</LayoutContainer>
    </LayoutWrapper>
  );
};

export default Layout;
