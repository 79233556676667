import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Layout from "./components/Layout";
import Home from "./pages/Home";
import Gallery from "./pages/Gallery";
import Contact from "./pages/Contact";
import "normalize.css";
import "reset.css";
import "./App.css";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import { UserAuthContextProvider } from "./context/User";
import { LogIn } from "./pages/Admin/components/LogIn";
import { Upload } from "./pages/Admin/components/Upload";
import ProtectedRoute from "./components/ProtectedRoute";
import { Testimonials } from "./pages/Testimonials";
import { UploadTestimonials } from "./pages/Admin/components/UploadTestimonials";

const App = () => {
  return (
    <>
      <Layout>
        <BrowserRouter basename="/">
          <Header>
            <Navbar />
          </Header>
          <UserAuthContextProvider>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/testimonials" element={<Testimonials />} />
              <Route path="/gallery" element={<Gallery />} />
              <Route path="/contact" element={<Contact />} />
              <Route path="/login" element={<LogIn />} />
              <Route
                path="/upload"
                element={
                  <ProtectedRoute>
                    <Upload />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/upload/testimonials"
                element={
                  <ProtectedRoute>
                    <UploadTestimonials />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </UserAuthContextProvider>
        </BrowserRouter>
      </Layout>
      <Footer />
    </>
  );
};

export default App;
