import React, { PropsWithChildren } from "react";
import { HeadingBold } from "../../components/Typography";
import Spacer from "../../components/Spacer";
import { Wrench } from "styled-icons/fa-solid";

export const Testimonials = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Spacer size={200} />
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <HeadingBold>Under Construction</HeadingBold>
          <Spacer size={20} />
          <Wrench color="white" size="50" />
        </div>
      </div>
      <Spacer size={200} />
    </>
  );
};
