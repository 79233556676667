import React from 'react';
import {
  CardWrapper,
  CardBody,
  CardImage,
  CardTitle,
  CardText
} from './styles';

interface Props {
  imgSrc: string;
  title: string;
  text: string;
}

const Card = ({ imgSrc, title, text }: Props) => {
  return (
    <CardWrapper>
      <CardImage src={imgSrc} />
      <CardBody>
        <CardTitle>{title}</CardTitle>
        <CardText>{text}</CardText>
      </CardBody>
    </CardWrapper>
  );
};

export default Card;
