import styled from "styled-components";
import { symbols } from "../../../../themes/Symbols";
import { HeadingMedium } from "../../../../components/Typography";

export const Wrapper = styled("div")`
  margin-top: 70px;
  display: flex;
  justify-content: space-between;
`;

export const SuccessText = styled(HeadingMedium)`
  color: ${symbols.colors.white};
`;

export const UploadButtonContainer = styled("div")`
  color: ${symbols.colors.white};

	& input[type=file]::file-selector-button {
		background-color: ${symbols.colors.blue};
		padding: ${symbols.spacing._10};
		border: 0;
		color: ${symbols.colors.white};
		border-radius: ${symbols.borderRadius._4};
	}
}
`;
