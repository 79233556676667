import React, { PropsWithChildren } from "react";
import { useUserAuth } from "../../../../context/User";
import { useNavigate } from "react-router-dom";
import { FirebaseError } from "firebase/app";
import { Button } from "../../../../components/Button";

export const Logout = ({ children }: PropsWithChildren) => {
  const userAuth = useUserAuth();
  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await userAuth?.logOut();
      navigate("/login");
    } catch (error) {
      if (error instanceof FirebaseError) {
        console.log(error.code);
      }
    }
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <Button buttonText="Log out" onClick={handleLogout} />
      </div>
      {children}
    </div>
  );
};
