import styled from "styled-components";
import { Field } from "formik";
import { symbols } from "../../themes/Symbols";
import { Text } from "../../components/Typography";

export const FormIntroText = styled(Text)`
  color: ${symbols.colors.black};
  text-align: center;
  padding-bottom: ${symbols.spacing._22};
`;

export const FormRequiredField = styled.span`
  color: ${symbols.colors.monza};
`;

export const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${symbols.colors.black};
  padding: ${symbols.spacing._30}
  border-radius: ${symbols.borderRadius._8};
`;

export const FormContainer = styled.form`
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const FormField = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: ${symbols.spacing._16};
  padding-left: ${symbols.spacing._4};
  padding-right: ${symbols.spacing._4};
`;

export const FormLabel = styled.label`
  padding-bottom: ${symbols.spacing._4};
  color: ${symbols.colors.white};
`;

export const FormErrorMessage = styled.div`
  padding-top: ${symbols.spacing._4};
  font-size: ${symbols.fontSize._12};
  color: ${symbols.colors.monza};
`;

export const FormStatusMessage = styled.div<{ error: boolean }>`
  background-color: ${(props) =>
    props.error ? symbols.colors.monza : symbols.colors.shamrock};
  border-radius: ${symbols.borderRadius._8};
  margin: ${symbols.spacing._30} 0;
  color: ${symbols.colors.white};
  padding: ${symbols.spacing._20};
`;

// TODO: use Input from components
export const FormInput = styled(Field)`
  font-size: ${symbols.fontSize._14};
  padding: ${symbols.spacing._10};
  border-radius: ${symbols.borderRadius._4};
  border-style: solid;
  border-width: 1px;
  border-color: ${symbols.colors.white};
  &.text-input.error {
    border-color: ${symbols.colors.monza};
  }
`;

export const FormButton = styled.button`
  background-color: ${symbols.colors.deepPink};
  padding: ${symbols.spacing._10};
  border: 0;
  color: ${symbols.colors.white};
  border-radius: ${symbols.borderRadius._4};
`;
