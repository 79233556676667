import styled from "styled-components";
import { symbols } from "../../themes/Symbols";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const NavBar = styled.ul`
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: ${symbols.media.small}) {
    &.collapsed {
      display: flex;
      flex-direction: column;
      overflow: hidden;
      padding-top: ${symbols.spacing._30}
      max-height: 0;
      transition-duration: 0.4s;
      transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
      &.expanded {
        overflow: hidden;
        max-height: ${symbols.size.collapsedNavigation};
        transition-duration: 0.4s;
        transition-timing-function: ease-in;
      }
    }
  }
`;

export const Logo = styled.img`
  display: flex;
  max-width: 30%;

  @media only screen and (max-width: ${symbols.media.small}) {
    margin-bottom: 20px;
    max-width: 40%;
  }
`;

export const NavLink = styled(Link)<{ active: boolean }>`
  color: ${symbols.colors.white};
  font-size: ${symbols.fontSize._18};
  margin: 0 ${symbols.spacing._20};
  text-decoration: none;
  ${(props) =>
    props.active && `border-bottom: solid 2px ${symbols.colors.deepPink};`}
  &:hover {
    border-bottom: solid 2px ${symbols.colors.blue};
  }
  @media only screen and (max-width: ${symbols.media.small}) {
    margin: 0;
    padding-bottom: ${symbols.spacing._10};
    &:hover {
      border-bottom: 0;
    }
  }
`;

export const BurgerIcon = styled(FontAwesomeIcon)`
  color: ${symbols.colors.white};
  display: none;
  font-size: ${symbols.fontSize._20};

  @media only screen and (max-width: ${symbols.media.small}) {
    display: block;
    float: right;
    align-content: center;
  }
`;
