export const symbols = {
  colors: {
    deepPink: "rgba(236, 0, 139, 1)",
    monza: "rgba(207, 0, 15, 1)",
    black: "rgba(0, 0, 0, 1)",
    white: "rgba(255, 255, 255, 1)",
    darkGray: "rgba(77, 77, 77, 1)",
    veryDarkGray: "rgba(10, 10, 10, 1)",
    shamrock: "rgba(46, 204, 113, 1)",
    silver: "rgba(191, 191, 191, 1)",
    blue: "rgba(3,155,220, 1)",
  },
  size: {
    layoutWidth: "1140px",
    sliderHeight: "500px",
    sliderNavigation: "10px",
    sliderControls: "40px",
    collapsedNavigation: "500px",
    containerSmall: "750px",
    containerLarge: "970px",
    containerXLarge: "1170px",
  },
  spacing: {
    _4: "4px",
    _10: "10px",
    _16: "16px",
    _20: "20px",
    _22: "22px",
    _30: "30px",
    _100: "100px",
  },
  borderRadius: {
    _4: "4px",
    _8: "8px",
  },
  fontSize: {
    _12: "12px",
    _14: "14px",
    _18: "18px",
    _20: "20px",
    _24: "24px",
    _30: "30px",
    _50: "50px",
    _68: "68px",
  },
  fontWeight: {
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    extraBold: 800,
  },
  lineHeight: {
    _18: "18px",
    _22: "22px",
    _24: "24px",
    _28: "28px",
    _34: "34px",
    _44: "44px",
    _54: "54px",
  },
  media: {
    small: "768px",
    large: "992px",
    xLarge: "1200px",
  },
};
