import React, { useEffect, useLayoutEffect, useState } from "react";
import { getDownloadURL, getStorage, listAll, ref } from "firebase/storage";
import { Gallery as ReactGridGallery, Image } from "react-grid-gallery";
import Lightbox from "react-image-lightbox";
import Spacer from "../../components/Spacer";
import "react-image-lightbox/style.css";

const Gallery = () => {
  const [imageList, setImageList] = useState<Image[]>([]);
  const [index, setIndex] = useState(-1);

  const currentImage = imageList[index];
  const nextIndex = (index + 1) % imageList.length;
  const nextImage = imageList[nextIndex] || currentImage;
  const prevIndex = (index + imageList.length - 1) % imageList.length;
  const prevImage = imageList[prevIndex] || currentImage;

  const handleClick = (index: number, item: Image) => setIndex(index);
  const handleClose = () => setIndex(-1);
  const handleMovePrev = () => setIndex(prevIndex);
  const handleMoveNext = () => setIndex(nextIndex);

  const storage = getStorage();
  const listRef = ref(storage, "images");

  useEffect(() => {
    listAll(listRef)
      .then((response) => {
        response.items.forEach((item) => {
          getDownloadURL(item).then((url) => {
            setImageList((prev) => [
              ...prev,
              { src: url, width: 320, height: 180 },
            ]);
          });
        });
      })
      .catch((error) => {
        console.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.juicer.io/embed/elightbarsltd/embed-code.js";
    script.async = true;
    script.defer = true;
    script.onload = () => {
      dispatchEvent(new Event("load"));
      dispatchEvent(new Event("DOMContentLoaded"));
    };
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <Spacer size={50}></Spacer>
      {/*<div>
         <ReactGridGallery
          images={imageList}
          onClick={handleClick}
          enableImageSelection={false}
          tileViewportStyle={{
            display: "block",
            overflow: "hidden",
            height: "180px",
            width: "270px",
          }}
          thumbnailStyle={{
            display: "block",
            height: "100%",
            width: "100%",
            objectFit: "cover",
          }}
        />
        {!!currentImage && (
          <Lightbox
            mainSrc={currentImage.src}
            imageTitle={currentImage.caption}
            mainSrcThumbnail={currentImage.src}
            nextSrc={nextImage.src}
            nextSrcThumbnail={nextImage.src}
            prevSrc={prevImage.src}
            prevSrcThumbnail={prevImage.src}
            onCloseRequest={handleClose}
            onMovePrevRequest={handleMovePrev}
            onMoveNextRequest={handleMoveNext}
          />
        )}
      </div> */}
      <iframe
        src="https://www.juicer.io/api/feeds/elightbarsltd/iframe"
        frameBorder="0"
        width="100%"
        height="1000"
        style={{ display: "block", margin: "0 auto" }}
      ></iframe>
      <Spacer size={100}></Spacer>
    </>
  );
};

export default Gallery;
