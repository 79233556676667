import styled from "styled-components";
import { symbols } from "../../themes/Symbols";

export const Button = styled.button<{ primary: boolean; secondary?: boolean }>`
  ${({ primary, secondary }) =>
    `background-color: ${
      primary ? symbols.colors.deepPink : secondary && symbols.colors.blue
    }`};
  padding: ${symbols.spacing._10};
  border: 0;
  color: ${symbols.colors.white};
  border-radius: ${symbols.borderRadius._4};
  cursor: pointer;

  &:disabled {
    background-color: ${symbols.colors.darkGray};
    cursor: not-allowed;
  }
`;
