import styled from 'styled-components';
import { symbols } from '../../themes/Symbols';

export const Wrapper = styled.div`
  width: 100%;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${symbols.spacing._30} 0;

  @media only screen and (max-width: ${symbols.media.small}) {
    display: block;
  }
`;
