import { Input } from "../../../../components/Input";

export const UploadTestimonials = () => {
  return (
    <div>
      <Input placeholder="Name" />
      <Input placeholder="Testimonial" />
    </div>
  );
};
