import React from "react";
import {
  FooterWrapper,
  FooterHeading,
  FooterContainer,
  FooterItem,
  FooterLink,
  FooterGroup,
  FooterSocial,
  FooterCopyRightContainer,
} from "./styles";
import Layout from "../Layout";
import { symbols } from "../../themes/Symbols";
import { Mail } from "styled-icons/feather/Mail";
import { PhoneCall } from "styled-icons/feather/PhoneCall";
import { Whatsapp } from "styled-icons/icomoon/Whatsapp";
import { Text } from "../Typography";
import { Facebook } from "styled-icons/icomoon/Facebook";
import { Instagram } from "styled-icons/icomoon/Instagram";

const Footer = () => {
  return (
    <>
      <FooterWrapper>
        <Layout bgColor={symbols.colors.veryDarkGray}>
          <FooterContainer>
            <FooterGroup>
              <FooterHeading>Contact</FooterHeading>
              <FooterItem>
                <Mail color={symbols.colors.white} size={20} />
                <FooterLink href="mailto: enquiries@elightproductions.com">
                  enquiries@elightproductions.com
                </FooterLink>
              </FooterItem>
              <FooterItem>
                <PhoneCall color={symbols.colors.white} size={20} />
                <FooterLink>07940 070062</FooterLink>
              </FooterItem>
            </FooterGroup>
            <FooterGroup>
              <FooterHeading>Social</FooterHeading>
              <FooterSocial>
                <FooterLink
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=447940070062"
                >
                  <Whatsapp color={symbols.colors.white} size={20} />
                </FooterLink>
                <FooterLink
                  target="_blank"
                  href="https://en-gb.facebook.com/elightproductions/"
                >
                  <Facebook color={symbols.colors.white} size={20} />
                </FooterLink>
                <FooterLink
                  target="_blank"
                  href="https://www.instagram.com/elightproductions/"
                >
                  <Instagram color={symbols.colors.white} size={20} />
                </FooterLink>
              </FooterSocial>
            </FooterGroup>
          </FooterContainer>
        </Layout>
      </FooterWrapper>
      <Layout>
        <FooterCopyRightContainer>
          <Text>&copy; E-Light Productions 2020</Text>
        </FooterCopyRightContainer>
      </Layout>
    </>
  );
};

export default Footer;
