import React from 'react';
import styled from 'styled-components';
import { symbols } from '../../themes/Symbols';

interface Props {
  size: number;
  color?: string;
}

const Space = styled.div<Props>`
  height: ${props => (props.size ? `${props.size}px` : null)};
  background-color: ${props =>
    props.color ? props.color : symbols.colors.black};
  width: 100%;
`;

const Spacer = ({ size, color }: Props) => {
  return <Space size={size} color={color}></Space>;
};

export default Spacer;
