import React, { useEffect, useState } from "react";
import { Input } from "../../../../components/Input";
import { Container, Wrapper } from "./styles";
import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/Button";
import { HeadingBold } from "../../../../components/Typography";
import { FormErrorMessage } from "../../../Contact/styles";
import { ErrorCodes } from "../types";
import { useUserAuth } from "../../../../context/User";
import { FirebaseError } from "firebase/app";
import Spacer from "../../../../components/Spacer";
import { Eye, EyeSlash } from "styled-icons/fa-solid";

export const LogIn = () => {
  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [passwordShown, setPasswordShown] = useState(false);

  const userAuth = useUserAuth();

  const handleLogin = async () => {
    setError(null);
    try {
      await userAuth?.logIn(email, password);
      navigate("/upload");
    } catch (error) {
      setIsLoading(false);
      if (error instanceof FirebaseError) {
        const errorCode = error.code;
        setError(errorCode);
      }
    }
  };

  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  useEffect(() => {
    if (userAuth?.user) {
      navigate("/upload");
    }
  }, [userAuth?.user, navigate]);

  return (
    <>
      <Spacer size={150} />
      <Wrapper>
        <Container>
          <div style={{ marginBottom: "20px" }}>
            <HeadingBold color="white">Login</HeadingBold>
          </div>
          <Input
            inputWrapperStyles={{ marginBottom: "20px", display: "flex" }}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div style={{ position: "relative" }}>
            <Input
              inputWrapperStyles={{ marginBottom: "20px", display: "flex" }}
              onChange={(e) => setPassword(e.target.value)}
              type={passwordShown ? "text" : "password"}
            />
            {passwordShown ? (
              <EyeSlash
                color="black"
                size="20"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 10,
                  top: 10,
                }}
                onClick={togglePasswordVisiblity}
              />
            ) : (
              <Eye
                color="black"
                size="20"
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  right: 10,
                  top: 10,
                }}
                onClick={togglePasswordVisiblity}
              />
            )}
          </div>
          <Button
            buttonText="Log In"
            isLoading={isLoading}
            onClick={handleLogin}
          />
          <FormErrorMessage>
            {error === ErrorCodes.UserNotFound
              ? "User does not exist"
              : error === ErrorCodes.InvalidEmail
              ? "Please enter a valid email address"
              : error === ErrorCodes.MissingPassword
              ? "Please enter a password"
              : null}
          </FormErrorMessage>
        </Container>
      </Wrapper>
      <Spacer size={200} />
    </>
  );
};
