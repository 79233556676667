import styled from 'styled-components';
import { symbols } from '../../themes/Symbols';

export const Button = styled.button<{ arrowType: string }>`
  color: ${symbols.colors.white} !important;
  width: ${symbols.size.sliderControls} !important;
  height: ${symbols.size.sliderControls} !important;
  z-index: 2;
  background-color: ${symbols.colors.black} !important;
  left: ${props => (props.arrowType === 'prev' ? '0 !important' : '')};
  right: ${props => (props.arrowType === 'next' ? '0 !important' : '')};
  padding: ${symbols.spacing._10} !important;
`;
