import React from "react";
import { Formik } from "formik";
import {
  FormButton,
  FormInput,
  FormLabel,
  FormField,
  FormRequiredField,
  FormContainer,
  FormErrorMessage,
  FormWrapper,
  FormIntroText,
} from "./styles";
import * as Yup from "yup";
import Spacer from "../../components/Spacer";
import { symbols } from "../../themes/Symbols";

interface FormValues {
  name: string;
  email: string;
  phone: string;
  enquiry: string;
}

const Contact = () => {
  const handleSubmit = async (values: FormValues) => {
    console.log({ values });
    fetch("https://formsubmit.co/ajax/enquiries@elightproductions.com", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({
        values,
      }),
    })
      .then((response) => response.json())
      .then((data) => console.log(data))
      .catch((error) => console.log(error));
  };

  return (
    <>
      <Spacer size={50}></Spacer>
      <FormWrapper>
        <FormIntroText>
          If you would like to get in touch regarding our mobile bars and other
          services, please leave your name, email address and number. We will
          contact you as soon as possible. Thank you
        </FormIntroText>
        <Formik
          initialValues={{ name: "", email: "", phone: "", enquiry: "" }}
          onSubmit={(values) => handleSubmit(values)}
          validationSchema={Yup.object().shape({
            name: Yup.string().required("Please enter a name"),
            email: Yup.string()
              .email()
              .required("Please enter a valid email address"),
            phone: Yup.number()
              .min(11, "Please enter a valid phone number")
              .required("Please enter a valid phone number"),
            enquiry: Yup.string().required("Please leave an enquiry message"),
          })}
        >
          {(props) => {
            const {
              values,
              touched,
              errors,
              isSubmitting,
              handleChange,
              handleBlur,
              handleSubmit,
            } = props;
            return (
              <FormContainer onSubmit={handleSubmit}>
                <FormField>
                  <FormLabel htmlFor="name">
                    Name <FormRequiredField>*</FormRequiredField>
                  </FormLabel>
                  <FormInput
                    id="name"
                    placeholder="Enter your name"
                    type="text"
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.name && touched.name
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  {errors.name && touched.name && (
                    <FormErrorMessage className="input-feedback">
                      {errors.name}
                    </FormErrorMessage>
                  )}
                </FormField>
                <FormField>
                  <FormLabel htmlFor="email">
                    Email <FormRequiredField>*</FormRequiredField>
                  </FormLabel>
                  <FormInput
                    id="email"
                    placeholder="Enter your email"
                    type="text"
                    value={values.email}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.email && touched.email
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  {errors.email && touched.email && (
                    <FormErrorMessage className="input-feedback">
                      {errors.email}
                    </FormErrorMessage>
                  )}
                </FormField>
                <FormField>
                  <FormLabel htmlFor="phone">
                    Phone <FormRequiredField>*</FormRequiredField>
                  </FormLabel>
                  <FormInput
                    id="phone"
                    placeholder="Enter your phone number"
                    type="tel"
                    value={values.phone}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={
                      errors.phone && touched.phone
                        ? "text-input error"
                        : "text-input"
                    }
                  />
                  {errors.phone && touched.phone && (
                    <FormErrorMessage className="input-feedback">
                      {errors.phone}
                    </FormErrorMessage>
                  )}
                </FormField>
                <FormField>
                  <FormLabel htmlFor="enquiry">
                    Enquiry <FormRequiredField>*</FormRequiredField>
                  </FormLabel>
                  <FormInput
                    id="enquiry"
                    as="textarea"
                    rows={3}
                    value={values.enquiry}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {errors.enquiry && touched.enquiry && (
                    <FormErrorMessage className="input-feedback">
                      {errors.enquiry}
                    </FormErrorMessage>
                  )}
                </FormField>
                <Spacer size={30} color={symbols.colors.black} />
                <FormButton type="submit" disabled={isSubmitting}>
                  Submit
                </FormButton>
              </FormContainer>
            );
          }}
        </Formik>
      </FormWrapper>
      <Spacer size={100}></Spacer>
    </>
  );
};

export default Contact;
