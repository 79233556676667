import React from "react";
import { Button as StyledButton } from "./styles";
import { Spinner } from "styled-icons/fa-solid";

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  primary?: boolean;
  secondary?: boolean;
  buttonText: string;
  isLoading?: boolean;
  onClick: () => void;
}

export const Button = ({
  buttonText,
  isLoading,
  primary,
  secondary,
  ...props
}: Props) => {
  return (
    <StyledButton primary={!secondary} secondary={secondary} {...props}>
      {isLoading ? <Spinner /> : buttonText}
    </StyledButton>
  );
};
