import React, { forwardRef, useEffect, useState, useRef } from "react";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Button } from "./styles";
import { ChevronRight } from "styled-icons/fa-solid/ChevronRight/ChevronRight";
import { ChevronLeft } from "styled-icons/fa-solid/ChevronLeft/ChevronLeft";

interface Props {
  images: string[];
  settings?: Settings;
  nav?: boolean;
}

export const SlickSlider = forwardRef(
  ({ images, settings, nav }: Props, ref) => {
    console.log({ images });

    const mainSliderRef = useRef<Slider>(null);
    const navSliderRef = useRef<Slider>(null);
    const [mainSlider, setMainSlider] = useState<Slider | null>();
    const [navSlider, setNavSlider] = useState<Slider | null>();

    useEffect(() => {
      setMainSlider(mainSliderRef.current);
      setNavSlider(navSliderRef.current);
    }, [mainSlider, navSlider]);

    const mainSettings = {
      ...settings,
      className: "main-slider",
      prevArrow: (
        <Button
          arrowType="prev"
          as={(props: any) => <ChevronLeft {...props} />}
        />
      ),
      nextArrow: (
        <Button
          arrowType="next"
          as={(props: any) => <ChevronRight {...props} />}
        />
      ),
    };

    const navSettings = {
      slidesToShow: images.length,
      swipeToSlide: true,
      focusOnSelect: true,
      className: "nav-slider",
      arrows: false,
    };

    return (
      <>
        {/* @ts-ignore */}
        <Slider {...mainSettings} asNavFor={navSlider} ref={mainSliderRef}>
          {images.map((image, index) => (
            <img alt={`${index}`} src={image} />
          ))}
        </Slider>
        {nav && (
          // @ts-ignore
          <Slider {...navSettings} asNavFor={mainSlider} ref={navSliderRef}>
            {images.map((image, index) => (
              <img alt={`${index}`} src={image} />
            ))}
          </Slider>
        )}
      </>
    );
  }
);
