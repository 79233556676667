import styled from 'styled-components';
import { symbols } from '../../themes/Symbols';

export const HeadingMedium = styled.h1`
  color: ${symbols.colors.black};
  font-weight: ${symbols.fontWeight.medium};
  font-size: ${symbols.fontSize._24};
  line-height: ${symbols.lineHeight._28};
`;

export const HeadingBold = styled.h1`
  color: ${symbols.colors.white};
  font-weight: ${symbols.fontWeight.bold};
  font-size: ${symbols.fontSize._30};
  line-height: ${symbols.lineHeight._34};
`;

export const HeadingExtraBold = styled.h1`
  color: ${symbols.colors.white};
  font-weight: ${symbols.fontWeight.extraBold};
  font-size: ${symbols.fontSize._50};
  line-height: ${symbols.lineHeight._54};
`;

export const Text = styled.p<{ large?: boolean }>`
  color: ${symbols.colors.white};
  font-size: ${props =>
    props.large ? symbols.fontSize._18 : symbols.fontSize._14};
  line-height: ${props =>
    props.large ? symbols.lineHeight._22 : symbols.lineHeight._18};
`;

export const LogoText = styled.h1`
  color: ${symbols.colors.deepPink};
  font-size: ${symbols.fontSize._68};
  line-height: ${symbols.lineHeight._44};
  font-family: 'MacrameBVTwo';
`;

export const LogoBottomText = styled.p`
  font-size: ${symbols.fontSize._20};
  color: ${symbols.colors.white};
  text-align: right;
`;
